import React, { useContext, useEffect, useState } from "react";
import "./ScanMartStore.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "react-simple-star-rating";
import TrandBee from "./TrandBee";
import Veggies_Section_Card from "../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Online_Store from "../../Common-Component/Online_Store/Online_Store";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Veiw_All_Card from "../../Common-Component/Veiw_All_Card/Veiw_All_Card";
import { Category } from "../../../utils/apis/Product/Product";
import { NearSellerGet } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { sellerCategory } from "../../../utils/apis/SellerApi/SellerApi";
import { Link, useNavigate } from "react-router-dom";
import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";

const ScanMartStore = () => {
  const [rating, setRating] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [likedProducts, setLikedProducts] = useState([]); // State to track liked products
  const [addCartModal, setAddCartModal] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleReset = () => {
    // Set the initial value
    setRating(0);
  };

  const handleLike = (index) => {
    setLikedProducts((prevLikedProducts) => {
      const updatedLikedProducts = [...prevLikedProducts];
      updatedLikedProducts[index] = !updatedLikedProducts[index];
      return updatedLikedProducts;
    });
  };

  const { IMG_URL, getData, triggerCartUpdate } = useContext(Context);
  const [activeCategory, setActiveCategory] = useState("first");
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category();
    if (res?.success) {
      setCategory(res?.data);
    }
  };

  const [seller, setSeller] = useState([]);

  console.log(seller, "seller");

  const getSeller = async (value) => {
    console.log(value, "value");

    if (value) {
      await setActiveCategory(value?.name);
      await getSellerByCat(value?.id);
    } else {
      await setActiveCategory("first");
      const res = await NearSellerGet(city, "");
      if (res?.success) {
        setSeller(res?.data);
      }
    }
  };

  const [sellerByCategory, setSellerByCategory] = useState([]);
  console.log(sellerByCategory, "sellerByCategory");

  const getSellerByCat = async (id) => {
    const res = await getData(
      `/without-login/home/seller-product-category?category_id=${id}`
    );
    if (res?.success) {
      setSellerByCategory(res?.data);
      setSeller("");
    }
  };

  const navigate = useNavigate();
  const handleNext = async (id) => {
    const res = await sellerCategory(id);
    if (res?.success) {
      localStorage.setItem("seller_id", id);
      navigate(`/product`);
    }
  };

  const [city, setCity] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch city name using reverse geocoding
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          )
            .then((response) => response.json())
            .then((data) => {
              setCity(data.address.city);
            })
            .catch((error) => {
              console.error("Error fetching city:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    getCategory();
    getSeller();
  }, [city]);

  useEffect(() => {
    triggerCartUpdate();
  }, [addCartModal]);
  useEffect(() => {
    getSellerByCat();
  }, []);

  return (
    <>
      <section className="near-mart-store">
        <div className="container">
          <div className="heading-holder">
            <h1>Near by shops</h1>
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            activeKey={activeCategory}
          >
            <Nav variant="pills" className="flex-row">
              <Swiper
                className="mySwiper1"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  420: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  486: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 9,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 10,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 8,
                    spaceBetween: 10,
                  },
                }}
              >
                <SwiperSlide className="product-slide">
                  <Nav.Item>
                    <Nav.Link
                      className={activeCategory === "first" ? "active" : ""}
                      onClick={() => getSeller()}
                    >
                      All
                    </Nav.Link>
                  </Nav.Item>
                </SwiperSlide>

                {category?.map((value, index) => (
                  <SwiperSlide className="product-slide" key={index}>
                    <Nav.Item>
                      <Nav.Link
                        className={
                          activeCategory == value?.name ? "active" : ""
                        }
                        onClick={() => getSeller(value)}
                      >
                        {value?.name}
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Nav>

            <Row>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey={activeCategory}>
                    {sellerByCategory?.map((value, index) => (
                      <div className="slider-sec" key={index}>
                        <div className="row">
                          <div className="col-lg-3 col-md-4 mt-5">
                            <Online_Store
                              rating={rating}
                              handleRating={handleRating}
                              image={IMG_URL + value?.logo}
                              heading={value?.store_name}
                              city={value?.city?.name}
                              distanceLocation="5 minutes away from your location"
                            />
                          </div>
                          <div className="col-lg-9 col-md-8 mt-5">
                            <Swiper
                              breakpoints={{
                                0: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                420: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                486: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 2.5,
                                  spaceBetween: 10,
                                },
                                992: {
                                  slidesPerView: 2.5,
                                  spaceBetween: 10,
                                },
                                1200: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                1400: {
                                  slidesPerView: 4.5,
                                  spaceBetween: 10,
                                },
                                1900: {
                                  slidesPerView: 4.5,
                                  spaceBetween: 10,
                                },
                              }}
                              navigation={true}
                              modules={[Navigation, Pagination]}
                              pagination={{
                                dynamicBullets: true,
                              }}
                              className="mySwiper"
                              onSwiper={(swiper) => setSwiperInstance(swiper)}
                            >
                              {value?.products.map((item, index) => (
                                <SwiperSlide
                                  key={`${index}-${item.detailsLink}`}
                                >
                                  <Veggies_Section_Card
                                    // detailsLink={item.detailsLink}
                                    image={IMG_URL + item?.image1}
                                    vegetablename={item?.name}
                                    // weight={item.weight}
                                    price={item?.product_variants[0]?.s_price}
                                    offprice={item?.product_variants[0]?.mrp}
                                    product={item}
                                    setAddCartModal={setAddCartModal}
                                    variant={item?.product_variants[0]}
                                  />
                                </SwiperSlide>
                              ))}

                              <SwiperSlide>
                                <div onClick={() => handleNext(value?.user_id)}>
                                  <Veiw_All_Card />
                                </div>
                              </SwiperSlide>
                            </Swiper>

                            <div className="silder-btn">
                              <div
                                className="back-btn"
                                onClick={() => handleMainSliderClick("prev")}
                              ></div>
                              <div
                                className="next-btn"
                                onClick={() => handleMainSliderClick("next")}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                  <Tab.Pane eventKey="Third">Second tab content</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <SupermarketModal
            show={addCartModal}
            onHide={() => setAddCartModal(false)}
          />
        </div>
      </section>
    </>
  );
};

export default ScanMartStore;
