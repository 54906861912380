import React, { useState, useEffect, useContext } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { returnOrderGet } from "../../../utils/apis/myAccount/myAccount";

const PurchasedItemReturnModal = (props) => {
  const createdAt = props.data?.createdAt;
  const formattedDate = createdAt
    ? new Date(createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
    : "";

  const { IMG_URL } = useContext(Context);

  const [returnOrders, setReturnOrder] = useState([]);
  const getReturnOrders = async () => {
    const res = await returnOrderGet();
    if (res?.success) {
      setReturnOrder(res?.data);
    }
  };

  useEffect(() => {
    getReturnOrders();
  }, []);

  const stepPercentage = (() => {
    switch (props.data?.return_order?.return_status_id) {
      case 1:
        return 0;
      case 2:
        return 25;
      case 3:
        return 50;
      case 4:
        return 75;
      case 5:
        return 100;
      default:
        return 0;
    }
  })();

  return (
    <>
      <div className="Successfull_Modal_sec">
        <Modal
          className="Successfull_Modal Purchased_Items_Modal my-account"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="product-section-div ">
              <div className="row">
                <div className="col-xxl-5 col-lg-5 col-md-7 col-sm-6 col-12">
                  <div className="border-text-right">
                    <p className="date">Ordered Date: {formattedDate}</p>
                    <h6 className="items">
                      Purchased Items Name: {props.data?.product.name}
                    </h6>
                    <h6 className="price">
                      ₹{" "}
                      {props.data?.discount_price
                        ? (Number(props.data?.price ?? 0) +
                            (Number(props.data?.price ?? 0) *
                              Number(props.data?.tax_percent ?? 0)) /
                              100) *
                            props.data?.quantity -
                          Number(props.data?.discount_price)
                        : (Number(props.data?.price ?? 0) +
                            (Number(props.data?.price ?? 0) *
                              Number(props.data?.tax_percent ?? 0)) / 100) *
                          props.data?.quantity}
                    </h6>
                  </div>
                </div>
                <div className="col-xxl-7 col-lg-7 col-md-5 col-sm-6 col-12">
                  <div>
                    <p className="date">
                      Order ID : {props.data?.invoice_number}
                    </p>
                    <p className="date">
                      Payment Type : {props.data?.order?.payment_mode}
                    </p>
                    <p className="date">Payment Status : {"Paid"}</p>
                  </div>
                </div>
              </div>

              <div className="product-div">
                <div className="row">
                  {props.data?.orders_details?.map((detail, detailIndex) => (
                    <div
                      className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5"
                      key={detailIndex}
                    >
                      <div className="product">
                        <img
                          className="img-set"
                          src={
                            IMG_URL +
                            detail?.product_variant?.product_images[0]?.image
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pb-md-0 pb-sm-3 pb-4">
                <h6 className="order ">Order Return Tracking</h6>
              </div>
              <div className="stepper-div mb-md-5 mb-sm-5 ">
                <ProgressBar percent={stepPercentage}>
                  <Step>
                    {({ accomplished }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Return Requested</h6>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Return Approved</h6>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Product Picked</h6>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Refund Process</h6>
                        </div>
                      </div>
                    )}
                  </Step>
                  <Step>
                    {({ accomplished }) => (
                      <div
                        className={`indexedStep1 ${
                          accomplished ? "accomplished" : null
                        }`}
                      >
                        <div className="range-text-div">
                          <h6 className="placed">Refund Done</h6>
                        </div>
                      </div>
                    )}
                  </Step>
                </ProgressBar>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PurchasedItemReturnModal;