import { React, useState, useEffect, useContext } from "react";
import "./SecondVendor.css";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Veggies_Section_Card from "../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import { Context } from "../../../utils/context";

import { Products } from "../../../utils/apis/Product/Product";
import { useOutlet } from "react-router-dom";

import SupermarketModal from "../../Common_modal/Supermarket-modal/SupermarketModal";

const SecondVendor = ({ heading, product }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeHearts, setActiveHearts] = useState([]);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };





  const { IMG_URL } = useContext(Context);

  const [addCartModal, setAddCartModal] = useState(false);
  const [products, setProduct] = useState([]);
  const getProducts = async () => {
    const res = await Products(product?.category_id, "", "");
    if (res?.success) {
      setProduct(res?.data?.data);
    }
  }


  useEffect(() => {
    getProducts();
  }, [product]);


  return (
    <>
      <section className="Frequently-Bought">
        <Container>
          <h1 className="title">{heading}</h1>
          <Swiper
            pagination={{
              dynamicBullets: true,
              clickable: true
            }}
            modules={[Pagination, Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              486: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              1400: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              1900: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            className="mySwiper"
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {products?.map((product, index) => (
              <SwiperSlide key={index}>
                <Product_Card

                  image={IMG_URL + product?.image1}
                  product_id={product?.id}
                  text={product?.name}
                  subText={product?.short_description}
                  price={product?.variants[0]?.s_price}
                  variant_id={product?.variants[0]?.id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <SupermarketModal show={addCartModal} onHide={() => setAddCartModal(false)} />
          <div class="silder-btn">
            <div
              className="back-btn"
              onClick={() => handleMainSliderClick("prev")}
            ></div>
            <div
              className="next-btn"
              onClick={() => handleMainSliderClick("next")}
            ></div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SecondVendor;
