import React from "react";
import History from "./History/History";

function OrderHistory() {
  return (
    <>
      <History />
    </>
  );
}

export default OrderHistory;
