import React, { useEffect, useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import classNames from "classnames";
import { Context } from "../../utils/context";
import { AppSetup } from "../../utils/apis/common/Common";
import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal";
const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const { IMG_URL, postData } = useContext(Context);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [emailerror, setemailErrors] = useState("");

  const onSubmit = async (d) => {
    const res = await postData(`/without-login/info-subscribe`, d);
    if (res?.success) {
      reset();
      setModalShow(true)
      setTimeout(() => {
        setModalShow(false)
        window.scrollTo(0, 0)
      }, 3000);
    } else {
      setemailErrors(res?.message);
    }
  }

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  }

  useEffect(() => {
    getSetup();
  }, []);
  return (
    <>
      <section className="footer">
        <Container>
          {/* subscribe-banner-start */}
          <div className='footer-banner'>
            <div className="footer-bannermain">
              <div className="footer-imgdiv">
                <img
                  className="footerimg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Image/footer/individual.png"
                  }
                  alt="Logo"
                />
              </div>

              <div className="ban-contentmain">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-9 col-sm-12 ">
                    <div className="banconts">
                      <h4 className="newssetl">
                        Subscribe to our newsletter to get updates on our latest
                        collections for you
                      </h4>
                      <p className="getss">Get Special deals with our subscription</p>
                      <div className="email-box">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              {...register("email", {
                                required: "Email Id required",
                                pattern: {
                                  value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                  message: "Email Id Invalid",
                                },
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email,
                              })}
                            />
                            {errors.email && (
                              <span className="text-danger">{errors.email.message}</span>
                            )}
                            <InputGroup.Text id="basic-addon2">
                              <button type="submit" className='subscribe-btn'>Subscribe</button>
                              {/* <Common_Button
                                text="  Subscribe"
                                className='subscribe-btn'
                                type="submit"
                              /> */}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* subscribe-banner-end */}
          <div className='row'>
            <div className='col-lg-3 col-md-4  '>
              <Link as={Link} to="/"><img className='headlogo' src={IMG_URL + setups?.image} alt="Logo" /></Link>
              <div className='text-area-loaction'>
                <p>{setups?.address}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-8  ">
              <Nav
                defaultActiveKey="/"
                className="justify-content-lg-center justify-content-md-center justify-content-start"
              >
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/" onClick={() => window.scrollTo(0, 0)}>
                    HOME
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/near-me">
                    NEAR ME
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/about-us">
                    ABOUT US
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/contact-us">
                    CONTACT US
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/privacy">
                    PRIVACY POLICY
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/terms">
                    TERMS & CONDITION
                  </NavLink>
                </Nav.Item>
              </Nav>
              <div className="location-holder">
                <div className="call-holder me-4">
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    className="call-icon me-2"
                  />{" "}
                  <span className="me-2">{setups?.contact_no}</span>
                </div>
                <div className="call-holder ">
                  <FontAwesomeIcon
                    icon="fa-solid fa-envelope"
                    className="call-icon me-2"
                  />
                  <span>{setups?.email}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5">
              <div className="address-holder">
                <div className="text-holder">
                  <p>Follow Us On:</p>
                </div>
                <div className="social-icon">
                <Link to={"https://www.facebook.com"} target="blank"> <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/facebook.png"
                    }
                    alt="sociallogo"
                  /></Link>
                  <Link to={"https://in.linkedin.com/"} target="blank"> <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/linkdin.png"
                    }
                    alt="sociallogo"
                  /></Link>
                  <Link to={"https://x.com/"} target="blank"> <img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/twitter.png"
                    }
                    alt="sociallogo"
                  /></Link>
                  <Link to={"https://www.youtube.com/"} target="blank"><img
                    className="sociallogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/Icon/youtube.png"
                    }
                    alt="sociallogo"
                  /></Link> 
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="copyright-holder  text-center">
                <p>
                  Copyright © {new Date().getFullYear()} All Rights Reserved.{" "}
                  <Link to="https://profcymaglobal.com/" target="___blank">
                    Developed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Successfull_Modal
        message={`Successfully Subscribed`}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Footer;
