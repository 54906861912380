import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import PurchasedItemsReturnModal from "../../../Common_modal/Purchased_Items_Modal/PurchasedItemReturnModal";
import {
  orderGet,
  returnOrderGet,
} from "../../../../utils/apis/myAccount/myAccount";

const OrderReturnHistory = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState();

  // const [orders, setOrder] = useState([]);
  // const getOrders = async () => {
  //     const res = await orderGet();
  //     if (res?.success) {
  //         setOrder(res?.data);
  //     }
  // }

  // useEffect(() => {
  //     getOrders();
  // }, []);

  const [returnOrders, setReturnOrder] = useState([]);

  console.log(returnOrders, "returnOrders");

  const getReturnOrders = async () => {
    const res = await returnOrderGet();
    if (res?.success) {
      setReturnOrder(res?.data);
    }
  };

  useEffect(() => {
    getReturnOrders();
  }, []);

  return (
    <>
      <section className="my-account checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={"/my-account"}>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link to={"/order-history"}>Order history</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/my-account"}>
                <p>Back</p>
              </Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Order Date</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Payment Type</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {returnOrders?.length > 0 ? (
                    returnOrders?.map((value, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value?.invoice_number}</td>
                        <td>{(value?.createdAt).split("T")[0]}</td>
                        <td>{value?.delivery_date?.split("T")?.[0]}</td>
                        <td>
                          ₹{" "}
                          {value?.discount_price
                            ? (Number(value?.price ?? 0) +
                                (Number(value?.price ?? 0) *
                                  Number(value?.tax_percent ?? 0)) /
                                  100) *
                                value?.quantity -
                              Number(value?.discount_price)
                            : (Number(value?.price ?? 0) +
                                (Number(value?.price ?? 0) *
                                  Number(value?.tax_percent ?? 0)) /
                                  100) *
                              value?.quantity}
                        </td>
                        <td>{value?.order?.payment_mode}</td>
                        <td className="green-text">{"Paid"}</td>
                        <td className="red-text">
                          {value?.order_status?.order_status}
                        </td>
                        <td
                          className="view-text"
                          onClick={() => {
                            setData(value);
                            setModalShow(true);
                          }}
                        >
                          View
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9}>No Order History Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div className="span-holder">
                {/* <span>Showing 3 of 3 entries</span> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <PurchasedItemsReturnModal
        data={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default OrderReturnHistory;
