import React, { useContext, useEffect, useState } from "react";
import "./WalletTransaction.css";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Form,
  Modal,
  Table,
  InputGroup,
} from "react-bootstrap";
import classNames from "classnames";
import { Context } from "../../utils/context";
import { useForm } from "react-hook-form";
import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal";
import { getData } from "../../utils/api";
import { UserGet } from "../../utils/apis/myAccount/myAccount";
import CustomPagination from "../Common_modal/CustomPagination";
import Pagination from "react-bootstrap/Pagination";

const WalletTransaction = () => {
  const { postData, signin, userdata, setUserData } = useContext(Context);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [wallet, setWallet] = useState({ totalPages: 1, currentPage: 1 });

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const getOrders = async () => {
    const res = await getData(
      `/wallet/get-history?page=${onPageChange}&per_page=${perPage}`
    );
    if (res?.success) {
      setWallet(res?.data);
      setperPage(res?.data?.per_page);
      setCurrentPage(res?.data?.currentPage);
      settotalPages(res?.data?.totalPages);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();
  const [perPage, setperPage] = useState(5);
  const [successModal, setSuccessModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [walletError, setWalletError] = useState("");

  const onSubmit = async (data) => {
    const res = await postData(`/wallet/request`, data);
    if (res?.success) {
      setSuccessModal(true);
      setWalletError("");
      setTimeout(() => {
        setSuccessModal(false);
        handleClose(true);
        reset();
        getOrders();
        getUser();
      }, 3000);
    } else {
      setWalletError(res?.message);
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getOrders();
  }, [onPageChange, perPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modalShow, setModalShow] = React.useState(false);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
      reset(res?.data);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <section className="wallet desk-top-view-wallet">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-end mt-4 mb-5">
              <div className="amount-details pt-2">
                <p>
                  Available Wallet Amount : ₹ {userdata?.users_wallet?.amount}
                </p>
              </div>

              <div className="button-recharge ms-3">
                <button className="btn btn-recharge" onClick={handleShow}>
                  Withdraw Request
                </button>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <Table responsive bordered>
              <div className="table-responsive">
                <table className="table   wallet-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Transaction Amount</th>
                      <th>Transaction Remark</th>
                      <th>Transaction Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wallet?.data?.map((value, index) => (
                      <tr>
                        <td>
                          {new Date(value.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          {new Date(value.createdAt).toLocaleTimeString()}
                        </td>
                        <td>{value.type}</td>
                        <td>₹ {value?.amount}</td>
                        <td>{value?.remark}</td>
                        <td>
                          {(value?.wallet_request?.status == 1 && "Accepted") ||
                            (value?.wallet_request?.status == 0 &&
                              "Rejected") ||
                            (value?.order_id
                              ? "-"
                              : value?.wallet_request?.status == null &&
                                "Requested")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Table>
            <div className="paginationss">
              <CustomPagination
                onPageChange={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </section>

      <>
        <div className="withrow-request Reason_Modal">
          <Modal
            className="withrow_request Cancel_Reason_Modal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>Withdrawal Request</Modal.Header>
            <Form role="form">
              <Modal.Body>
                <div className="col-md-6">
                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Amount</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="amount"
                          placeholder="Amount"
                          className={classNames("", {
                            "is-invalid": errors?.amount,
                          })}
                          {...register("amount", {
                            required: "Amount is required",
                            validate: (value) => {
                              // Check if the value is a number and an integer
                              if (!Number.isInteger(Number(value))) {
                                return "Amount must be an integer.";
                              }

                              // Check if the value is non-negative
                              if (value < 0) {
                                return "Amount cannot be negative.";
                              }

                              // Check if the amount is less than or equal to wallet amount
                              if (value > userdata?.wallet_amount) {
                                return `Insufficient Wallet Balance`;
                              }

                              // If all conditions are satisfied, return true
                              return true;
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.amount && (
                        <span className="text-danger">
                          {errors.amount.message}
                        </span>
                      )}
                      <span className="text-danger">{walletError}</span>
                    </Form.Group>
                  </div>

                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Ifsc Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="ifsc_code"
                          placeholder="IFSC Code"
                          className={classNames("", {
                            "is-invalid": errors?.ifsc_code,
                          })}
                          {...register("ifsc_code", {
                            required: "IFSC Code is required",
                          })}
                        />
                      </InputGroup>
                      {errors.ifsc_code && (
                        <span className="text-danger">
                          {errors.ifsc_code.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>

                  <div className="main-form-section mt-3">
                    <Form.Group>
                      <Form.Label>Account Number</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="ac_no"
                          placeholder="Account Number"
                          className={classNames("", {
                            "is-invalid": errors?.ac_no,
                          })}
                          {...register("ac_no", {
                            required: "Account Number is required",
                          })}
                        />
                      </InputGroup>
                      {errors.ac_no && (
                        <span className="text-danger">
                          {errors.ac_no.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button className="close-btn" onClick={handleClose}>
                  Close
                </Button>
                <Button className="save-btn" onClick={handleSubmit(onSubmit)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </>
      <Successfull_Modal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        message={"Wallet Request Sent Successfully"}
      />
    </>
  );
};

export default WalletTransaction;
