import React from "react";
import "./TwoPayment.css";
import { Form } from "react-bootstrap";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";

const TwoPayment = ({
  handlePrevious,
  handleNext,
  addressData,
  addressId,
  paymentMethod,
  setPaymentMethod,
}) => {
  const handleMethod = async (value) => {
    await setPaymentMethod(value);
  };
  return (
    <>
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="adresmain">
            <h3>Thank You for Trusting Us</h3>
            <div className="adresss">
              <p className="primary">Primary Contact</p>
              <p className="disha">{addressData?.name}</p>
              <p className="disha">
                {addressData?.name +
                  "," +
                  addressData?.flat_home_bldg +
                  "," +
                  addressData?.col_land_strt}
              </p>
            </div>
          </div>
          <div className="Choose_Payment_Option">
            <div className="heading-holder">
              <h4>Choose Payment Option</h4>
            </div>

            <div>
              <div>
                <div className="radio-btn-input-holder">
                  <Form.Check // prettier-ignore
                    type="radio"
                    name="method"
                    label="Cash on delivery"
                    value="cod"
                    defaultChecked={paymentMethod === "cod" || true}
                    onClick={() => handleMethod("cod")}
                  />

                  <Form.Check // prettier-ignore
                    type="radio"
                    name="method"
                    label="Online"
                    value="online"
                    defaultChecked={paymentMethod === "online"}
                    onClick={() => handleMethod("online")}
                  />

                  <Form.Check // prettier-ignore
                    type="radio"
                    name="method"
                    label="Wallet"
                    value="wallet"
                    defaultChecked={paymentMethod === "wallet"}
                    onClick={() => handleMethod("wallet")}
                  />
                </div>
              </div>
            </div>
            {/* <div>
              <div className="payment_method_card bodr_bottom">
                <div className="heading-holder">
                  <h4>Pay with credit card</h4>
                </div>

                <div className="paycredit">
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="method"
                      value="visa"
                      defaultChecked={paymentMethod === 'visa'}
                      onClick={() => handleMethod('visa')}
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/visa.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="method"
                      value="master"
                      defaultChecked={paymentMethod === 'master'}
                      onClick={() => handleMethod('master')}
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/mastercard.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment_method_card">
                <div className="heading-holder">
                  <h4>Pay with UPI</h4>
                </div>

                <div className="paycredit">
                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="method"
                      value="phonepe"
                      defaultChecked={paymentMethod === 'phonepe'}
                      onClick={() => handleMethod('phonepe')}
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/phone-pay.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>

                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="method"
                      value="gpay"
                      defaultChecked={paymentMethod === 'gpay'}
                      onClick={() => handleMethod('gpay')}
                    />

                    <div className="payment-logo-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/G_pay.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>

                  <div className="flex-radio-input">
                    <Form.Check // prettier-ignore
                      type="radio"
                      name="method"
                      value="paytm"
                      defaultChecked={paymentMethod === 'paytm'}
                      onClick={() => handleMethod('paytm')}
                    />

                    <div className="payment-logo-holder">
                      <img
                        for="method"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Cash_On_Delivery_Process/Paytm.png"
                        }
                        className="payment-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="main-btn-div ">
            <Common_Button
              onClick={handlePrevious}
              text="Back"
              className=" back-btn"
            />

            <Common_Button
              onClick={handleNext}
              text="Save & Next"
              className=" save-btn"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TwoPayment;
