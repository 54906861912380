import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { verifyMobileOTP, sendMobileOTP } from "../../../../utils/apis/otp/otp";
import SuccessModal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import EmailOtpModal from "../../../Common-Component/EmailOtpModal/EmailOtpModal";
const Stepseven = ({ nextStep, prevStep, subPage, newUserContact }) => {
  const user_id = Cookies.get("user_id");
  const { getData, postData } = useContext(Context);
  const [otp, setOtp] = useState("");
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [fields, setFields] = useState(false);
  const [mouRead, setMouRead] = useState(false);

  const [mouError, setMouError] = useState("");
  const [errorGetOtp, setErrorGetOtp] = useState("");
  const [completeOtp, setCompletOtp] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [checked, setChecked] = useState(false);
  const [noVerified, setNoVerified] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");

  const otpClick = () => {
    setFields(true);
  };
  const inputRefs = useRef([]);

  useEffect(() => {
    if (fields && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [fields]);

  const handleOtpInputChange = (index, value) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);
    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };
  // const handleOtpChange = (index, value) => {
  //     if (!isNaN(value) && value !== "") {
  //         const newOtpInputs = [...otpInputs];
  //         newOtpInputs[index] = value;
  //         setOtpInputs(newOtpInputs);
  //         if (value.length === 1 && index < 3) {
  //             inputRefs.current[index + 1].focus();
  //         }

  //         if (index < 3) {
  //             setVerifyStat(true);
  //             document.getElementById(`otpInput-${index + 1}`).focus();
  //         } else {
  //             setVerifyStat(false);
  //         }
  //     } else if (value === "") {
  //         setVerifyStat(true);
  //         const newOtpInputs = [...otpInputs];
  //         newOtpInputs[index] = "";
  //         setOtpInputs(newOtpInputs);

  //         if (index > 0) {
  //             document.getElementById(`otpInput-${index - 1}`).focus();
  //         }
  //     }
  // };

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && !otpInputs[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleCheck = (e) => {
    setMouRead(e.target.checked);
    if (e.target.checked) {
      setMouError("");
    }
  };

  const GetOtp = async () => {
    const res = await sendMobileOTP(newUserContact);
    if (res?.success) {
      setFields(true);
      setModalShow(true);
      // setModalShow3(true);
      setSeconds(30);
      setTimeout(() => {
        setModalShow(false);
      }, 3000);
      return res;
    }
  };

  const VerifyOtp = async () => {
    if (otpInputs.join("") !== "") {
      const data = {
        contact_no: newUserContact,
        otp: otpInputs.join(""),
      };
      const res = await verifyMobileOTP(data);
      if (res?.success) {
        setFields(false);
        setNoVerified(true);
        setModalShow1(true);
        // setModalShow3(false);
        setSeconds(0);
        setTimeout(() => {
          setModalShow1(false);
        }, 3000);

        // const formData = new FormData();
        // formData.append("user_id", user_id);
        // formData.append("verified", 1);
        // formData.append("mou_read", 1);

        // try {
        //     const res = await postData("/seller/s-owner-details", formData);
        //     if (res?.success) {
        //         console.log(res?.data);
        //         nextStep();
        //     } else {
        //         // Handle the case when res.success is false
        //         console.error("Failed to submit data");
        //     }
        // } catch (error) {
        //     // Handle any errors that occur during the request
        //     console.error("An error occurred while submitting data:", error);
        // }
        // return res
      } else {
        setErrorGetOtp(res?.message);
      }
    } else {
      setErrorGetOtp("OTP is required");
    }
  };

  const onsubmit = async () => {
    if (!mouRead) {
      setMouError("Please read the MOU.");
      return;
    } else {
      setMouError("");
    }
    if (noVerified) {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("verified", 1);
      formData.append("mou_read", mouRead);

      try {
        const res = await postData("/seller/s-owner-details", formData);
        if (res?.success) {
          nextStep();
        } else {
          // Handle the case when res.success is false
          console.error("Failed to submit data");
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("An error occurred while submitting data:", error);
      }
    } else {
      setErrorGetOtp("Verify Mobile Number");
    }

    // return res
  };
  const getEditData = async () => {
    const res = await getData(`/seller/s-owner-details/${user_id}`);
    if (res?.success) {
      setNoVerified(res?.data?.verified ? true : false);
      setMouRead(res?.data?.mou_read ? true : false);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  const [verifyStat, setVerifyStat] = useState(true);

  const [isTimerExpired, setIsTimerExpired] = useState(false);

  useEffect(() => {
    const decrementTimer = () => {
      if (seconds > 0) {
        setIsTimerExpired(false);
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  useEffect(() => {
    // setOtp(["", "", "", ""]);
    setVerifyStat(true);
    setIsTimerExpired(false);
  }, []);

  return (
    <section className="personal-details-form">
      <div>
        {subPage === 0 ? (
          <div>
            <div className="stepsevenMain">
              <div className="imarkcontent warning_text">
                <p className="mb-0">
                  <FontAwesomeIcon
                    icon="fa-solid fa-triangle-exclamation "
                    className="me-2"
                  />{" "}
                  Kindly read carefully the Memorandum of Understanding (MOU)
                </p>
              </div>
              <div className="readMoumain">
                <p className="mb-2">Read MOU</p>

                <div className="row flex-column">
                  <div className="col-md-4 col-sm-4 col-3">
                    <Button type="button" className="moubtn">
                      View MOU
                    </Button>
                  </div>

                  <div className="col-md-4 col-sm-4 col-3">
                    <Button type="button" className="moubtn">
                      View Declaration
                    </Button>
                  </div>
                </div>

                <Form.Check
                  type="checkbox"
                  label="I have read the MOU and Decleration"
                  checked={mouRead}
                  onChange={(e) => handleCheck(e)}
                />
                {mouError && <p style={{ color: "red" }}>{mouError}</p>}
              </div>

              <div className="readMoumain">
                <p className="mb-2">Sign the Documents</p>
                <span className="mb-2">
                  (Verify OTP to digitally sign the documents)
                </span>
              </div>
              <Form>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    placeholder="9898989889"
                    value={newUserContact}
                    disabled
                  />

                  {noVerified ? (
                    <p className="getotptxt" style={{ color: "green" }}>
                      Verified
                    </p>
                  ) : (
                    <p className="getotptxt" onClick={GetOtp}>
                      Get Otp
                    </p>
                  )}
                </Form.Group>
                {errorGetOtp && <p style={{ color: "red" }}>{errorGetOtp}</p>}
                {fields && (
                  <>
                    <div className="col-lg-2 col-md-4 col-8 mx-auto">
                      <div className="row getotpinput">
                        {Array.from({ length: 4 }, (_, i) => (
                          <div className="col-lg-3 col-3" key={i}>
                            <Form.Control
                              type="text"
                              maxLength="1"
                              value={otpInputs[i]}
                              onKeyDown={(e) => handleBackspace(i, e)}
                              onChange={(e) =>
                                handleOtpInputChange(i, e.target.value)
                              }
                              // onChange={(e) => handleOtpChange(i, e.target.value)}
                              ref={(input) => (inputRefs.current[i] = input)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="get-otp text-center">
                      <p className="otp otptimer">
                        Enter OTP within{" "}
                        {`${Math.floor(seconds / 60)}:${(seconds % 60)
                          .toString()
                          .padStart(2, "0")}`}{" "}
                        seconds
                      </p>
                      <p className="otp text text-danger">{otpErr}</p>
                      <p>
                        {/* Didn't get the OTP?{" "} */}
                        {seconds == 0 && (
                          <span
                            // onClick={props.handleSendOtp}
                            style={{ cursor: "pointer" }}
                            className="clickresend"
                            onClick={GetOtp}
                          >
                            {" "}
                            Click to Resend.
                          </span>
                        )}
                      </p>
                    </div>

                    <div className=" mt-4 ">
                      <div className=""></div>
                      <div className="">
                        <div className="text-center">
                          {/* {verifyStat || !isTimerExpired && ( */}
                          <button
                            type="button"
                            className="mb-2 Verifyee"
                            onClick={() => VerifyOtp()}
                          >
                            Verify{" "}
                          </button>
                          {/* )} */}
                        </div>
                      </div>
                      <div className=""></div>
                    </div>
                    {completeOtp && (
                      <center>
                        <p style={{ color: "red" }}>{completeOtp}</p>
                      </center>
                    )}
                  </>
                )}
                <div className="text-end apply_now_btn">
                  <div className="registerContinueMain">
                    <Button onClick={prevStep} className="back-btn me-3">
                      Back
                    </Button>
                    {/* <Button className="tabs-btn" type="button" onClick={handleNextClick}>Next</Button> */}
                    <Button
                      className="tabs-btn"
                      type="button"
                      onClick={onsubmit}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        ) : (
          <div>
            <div className="stepeight">
              <div className="dulysigned">
                <p>Documents have been signed successfully</p>
              </div>
              <div className="stepeightbtn">
                <Button type="button" className="me-3">
                  View Signed MOU
                </Button>
                <Button type="button">View All E-Signed Docs</Button>
              </div>
              <div>
                <p className="mb-4 notifytxt">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="circleCheckicon"
                  />{" "}
                  (You will receive documents via e-mail)
                </p>
              </div>
            </div>
            <div className="text-end apply_now_btn">
              <div className="registerContinueMain">
                <Button onClick={prevStep} className="back-btn me-3">
                  Back
                </Button>
                <Button className="tabs-btn" type="button" onClick={nextStep}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <SuccessModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"OTP Sent"}
        subMessage={"Enter the OTP and Verify the Number"}
      />
      <SuccessModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        message={"OTP Verified"}
        // subMessage={"Enter the OTP and Verify the Number"}
      />

      {/* <EmailOtpModal
                show={modalShow3}
                seconds={seconds}
                otpErr={otpErr}
                setSeconds={setSeconds}
                verifyOTP={VerifyOtp}
                // handleSendOtp={() => handleSendOtp("email", "resend")}
                onHide={() => {
                    setModalShow3(false);
                    setErrorGetOtp("");
                }}
            /> */}
    </section>
  );
};

export default Stepseven;
