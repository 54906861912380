import React, { useState, useEffect, useContext } from "react";
import "./CounterSec.css";
import CountUp from "react-countup";
import { Context } from "../../../utils/context";
import { getCount } from "../../../utils/apis/common/Common";
const CounterSec = () => {
  const [count, setCount] = useState();

  const getProject = async () => {
    const res = await getCount();
    if (res?.success) {
      setCount(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);
  return (
    <>
      <section className="counter-sec">
        <div className="container">
          <div className="bg-image">
            <div className="imag-overlay"></div>
            <div className="row justify-content-center">
              <div className="heading-holder text-center mb-5">
                <h2>
                  Track real-time sales and customer engagement with our dynamic
                  e-commerce website counter section
                </h2>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                <div className="border-line">
                  <div className="total-holder text-center">
                    <h4>
                      <CountUp
                        start={0}
                        end={count?.productCount}
                        duration={4}
                        decimals={0}
                      />{" "}
                      +
                    </h4>
                    <p>Products</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                <div className="border-line">
                  <div className="total-holder text-center">
                    <h4>
                      <CountUp
                        start={0}
                        end={count?.sellerCount}
                        duration={4}
                        decimals={0}
                      />{" "}
                      +
                    </h4>
                    <p>Sellers</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-4">
                <div className="total-holder text-center">
                  <h4>
                    <CountUp
                      start={0}
                      end={count?.categoryCount}
                      duration={4}
                      decimals={0}
                    />{" "}
                    +
                  </h4>
                  <p>Product categories</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CounterSec;
