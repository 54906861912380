import React, { useState, useEffect, useContext } from "react";
import './OurStory.css'
import { Context } from "../../../utils/context";
import { allourstory, privacyPolicy } from "../../../utils/apis/common/Common";
import parse from 'html-react-parser';
const OurStory = () => {
    const [getprivacy, setPrivacy] = useState();
    const { getData, IMG_URL } = useContext(Context);
    const getProject = async () => {
        const res = await privacyPolicy();
        if (res?.success) {
            setPrivacy(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getProject();
    }, []);
    return (
        <>
            <section className='our-story'>
                <div className='container'>
                    <div className='row'>
                        <div className="text-center">
                        <h1 className="hot-title">Privacy Policy</h1>
                            <div className="title-border"></div>
                            <p className="sub-text my-4">  {typeof getprivacy?.description === 'string' ? parse(getprivacy?.description) : ''}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory