import React, { useContext, useEffect, useState } from "react";
import "./SelectByCategory.css";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import VegetableTab from "./vegetable-tabs/VegetableTab";
import FruitsTab from "./Fruit-tabs/FruitsTab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../../Common-Component/Popular_Product_Card/Popular_Product_Card";
import { Context } from "../../../utils/context";

import {
  Category,
  SubCategory,
  Products,
} from "../../../utils/apis/Product/Product";
import { useNavigate } from "react-router-dom";
const SeclectByCategory = ({ sellerData }) => {
  const { IMG_URL } = useContext(Context);

  const [numToShow, setNumToShow] = useState(4);
  const [activeCategory, setActive] = useState("");

  console.log("sellerData :----", sellerData);
  // const [categories, setCategory] = useState([]);
  // const getCategory = async () => {
  //   const res = await Category();
  //   if (res?.success) {
  //     setCategory(res?.data);
  //     handleSubCategory(res?.data[0]?.id);
  //   }
  // }

  const [subCategory, setSubCategory] = useState([]);
  const handleSubCategory = async (id, user_id) => {
    const res = await getProduct(id, user_id);
    if (res?.success) {
      setActive(id);
      setSubCategory(res?.data);
    }
  };

  const [products, setProducts] = useState([]);
  const getProduct = async (category_id, user_id) => {
    const storedSellerId = localStorage.getItem("seller_id");
    if (storedSellerId) {
      const res = await Products(category_id, "", "", user_id);
      if (res?.success) {
        setProducts(res?.data?.data);
      }
    }
  };

  // useEffect(() => {
  //   getCategory();
  // }, []);

  useEffect(() => {
    if (sellerData?.length > 0) {
      handleSubCategory(sellerData[0]?.category?.id, sellerData[0]?.user_id);
    }
    // getCategory();
  }, [sellerData]);

  const navigate = useNavigate("");

  const handleNext = async (id) => {
    navigate(`/select-from-category/${id}`);
  };

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <section className="select-by-category select-categories ">
        <div className="container">
          <div className="row">
            <div className="heading-holder">
              <h2 className="title ">Select by Category</h2>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Swiper
              className="mySwiper1"
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              modules={[Pagination, Navigation]}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                575: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },

                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 4.5,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 5.5,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 6.5,
                  spaceBetween: 10,
                },
              }}
            >
              {sellerData?.map((slide, index) => (
                <SwiperSlide
                  key={index}
                  className="product-slide"
                  onClick={() => {
                    handleNext(slide?.category?.id);
                  }}
                >
                  <Nav.Item>
                    <Nav.Link
                      className={
                        activeCategory === slide?.category?.id
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handleSubCategory(
                          slide?.category?.id,
                          slide.user_id
                        )
                      }
                    >
                      <Popular_Product_Card
                        className="product-image"
                        content={slide?.category?.name}
                        alt={slide?.name}
                        image={IMG_URL + slide?.category?.image}
                        imgClassName="category-image"
                      />
                      <div className="triangle-right"></div>
                    </Nav.Link>
                  </Nav.Item>
                </SwiperSlide>
              ))}

              {/* <div class="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                ></div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                ></div>
              </div> */}

            </Swiper>


            {sellerData?.map((slide, index) => (
              <Tab.Content>
                <Tab.Pane eventKey={index == 0 ? "first" : slide?.name}>
                  <div className="desktop_view_product_category_slider">
                    <Row>
                      {products.length > 0 ? (
                        products.slice(0, numToShow).map((category, index) => (
                          <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                            <Product_Card
                              image={IMG_URL + category?.image1}
                              text={category?.name}
                              product_id={category?.id}
                              subText={category?.short_description}
                              price={category?.variants[0]?.s_price}
                              variant_id={category?.variants[0]?.id}
                            />
                          </Col>
                        ))
                      ) : (
                        <div className="noprodiv">
                          <p>No Product Available</p>
                        </div>
                      )}
                    </Row>
                  </div>

                  {/* desktop_view_product_category_slider end */}

                  {/* mobile_view_product_category_slider start */}

                  <div className="mobile_view_product_category_slider">
                    <Row className="ms-0 me-0">
                      <Swiper
                        className="select-categories-slider mySwiper"
                        modules={[Pagination]}
                        pagination={{
                          dynamicBullets: true,
                        }}
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          420: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          486: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                          },
                          576: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          992: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                          },
                        }}
                      >
                        {products?.length > 0 ? (
                          products
                            .slice(0, numToShow)
                            .map((category, index) => (
                              <SwiperSlide>
                                <Product_Card
                                  image={IMG_URL + category?.image1}
                                  text={category?.name}
                                  product_id={category?.id}
                                  subText={category?.short_description}
                                  price={category?.variants[0]?.s_price}
                                />
                              </SwiperSlide>
                            ))
                        ) : (
                          <div className="noprodiv">
                            <p>No Product Available</p>
                          </div>
                        )}
                      </Swiper>
                    </Row>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            ))}
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default SeclectByCategory;
