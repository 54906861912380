import React from "react";
import "./Cancle_Reason_Modal.css";
import { Form, Modal } from "react-bootstrap";

function Return_Reason_Modal(props) {
  const handleReason = async (d) => {
    await props.setReason(d);
    props.onHide();
  };

  return (
    <>
      <div className="Reason_Modal">
        <Modal
          className=" Cancel_Reason_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton> Select a reason for return</Modal.Header>
          <Modal.Body>
            <div className="select_reason">
              <Form>
                <Form.Check
                  label="Product price has reduced"
                  name="name"
                  type="radio"
                  value="Product price has reduced"
                  onClick={() => handleReason("Product price has reduced")}
                  defaultChecked={props.reason === "Product price has reduced"}
                />

                <Form.Check
                  label="Ordered by mistake"
                  name="name"
                  type="radio"
                  value="Ordered by mistake"
                  onClick={() => {
                    handleReason("Ordered by mistake");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Ordered by mistake"}
                />
                <Form.Check
                  label="Incorrect product size/colour/type ordered"
                  name="name"
                  type="radio"
                  value="Incorrect product size/colour/type ordered"
                  onClick={() => {
                    handleReason("Incorrect product size/colour/type ordered");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={
                    props.reason ===
                    "Incorrect product size/colour/type ordered"
                  }
                />
                <Form.Check
                  label="Product not required anymore"
                  name="name"
                  type="radio"
                  value="Product not required anymore"
                  onClick={() => {
                    handleReason("Product not required anymore");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={
                    props.reason === "Product not required anymore"
                  }
                />
                <Form.Check
                  label="Product is damaged"
                  name="name"
                  type="radio"
                  value="Product is damaged"
                  onClick={() => {
                    handleReason("Product is damaged");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Product is damaged"}
                />
                {/* <Form.Check label="Other" name="name" type="radio"  value="Ordered by mistake"
                onClick={() => handleReason("Ordered by mistake")}/> */}
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Return_Reason_Modal;
