import React, { useContext, useState } from "react";
import { Table } from "react-bootstrap";

import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useRazorpay from "react-razorpay";

import { useNavigate } from "react-router-dom";

import { Checkout } from "../../../../utils/apis/orderProcess/orderProcess";
import Cart from "../../Cart/Cart";
import { Context } from "../../../../utils/context";
import { PuffLoader } from "react-spinners";

const ThreeOrder = ({ handlePrevious, paymentMethod, addressId }) => {
  const [modalShow, setModalShow] = useState(false);
  const [numberTwo, setNumberTwo] = useState(1);
  const [numbOne, setNumberOne] = useState(1);

  const navigate = useNavigate();

  const { taxRupees, reactLoader, setReactLoader } = useContext(Context);

  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
      navigate("/");
    }, 3000);
  };

  //Payment Gateway

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const [amount, setAmount] = useState();
  const [tax, setTotalTax] = useState();
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);

  const [walletError, setWalletError] = useState("");
  console.log(walletError, "walletError");

  const handleRazorpay = async (storedValue) => {
    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Local Mandai",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const finalFunction = async (razor = null) => {
    const data = {
      address_id: addressId,
      amount: Math.round(amount),
      discount: Math.round(discount),
      tax: Math.round(tax),
      payment_mode: paymentMethod,
      tax_rupees: Math.round(taxRupees),
    };
    if (coupon) {
      data.code_id = coupon;
    }
    if (razor) {
      data.payment_id = razor;
    }
    try {
      setReactLoader(true);
      const res = await Checkout(data);
      if (res?.success) {
        setWalletError("");
        setModalShow(true);
        hideModalWithDelay();
        setTimeout(() => {
          setReactLoader(false);
        }, 2000);
      } else {
        setWalletError(res?.code);
      }
    } catch (error) {
      setReactLoader(false);
      console.error("An error occurred during checkout:", error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <>
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="confirm_order Product_Cart threeorder">
            <Cart
              type={"checkout"}
              amount={amount}
              setAmount={setAmount}
              discount={discount}
              setDiscount={setDiscount}
              coupon={coupon}
              setCoupon={setCoupon}
              tax={tax}
              setTotalTax={setTotalTax}
            />
          </div>

          <div className="main-btn-div ">
            <p style={{ color: "red" }}>{walletError}</p>

            <div className="holder">
              <div className="btn_holder">
                <Common_Button
                  onClick={handlePrevious}
                  text="Back"
                  className=" back-btn"
                />
              </div>

              <div className="custom_holder">
                {reactLoader ? (
                  <div className="loder_holder">
                    <PuffLoader />
                  </div>
                ) : paymentMethod === "wallet" || paymentMethod === "cod" ? (
                  <Common_Button
                    onClick={() => finalFunction()}
                    type="button"
                    disabled={reactLoader}
                    text="Confirm Order"
                    className="save-btn"
                  />
                ) : (
                  <Common_Button
                    onClick={() => handleRazorpay(amount)}
                    text="Confirm Order"
                    className="save-btn"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your order has been placed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ThreeOrder;
