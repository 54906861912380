import React, { useState, useEffect, useContext } from "react";
import "./OurProduct.css";
import { Card, Col, CardFooter, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Product_Card from "../../Common-Component/Product_Card/Product_Card";

import { Context } from "../../../utils/context";
import { Products } from "../../../utils/apis/Product/Product";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
library.add(fas);

const OurProduct = () => {
  const navigate = useNavigate();
  const { IMG_URL, setShimmerLoader } = useContext(Context);

  const [products, setProducts] = useState([]);
  const getProduct = async () => {
    setShimmerLoader(true);
    const res = await Products();
    if (res?.success) {
      setProducts(res?.data?.data);
      setShimmerLoader(false);
    }
  };
  const [showAll, setShowAll] = useState(false);
  const [numToShow, setNumToShow] = useState(4); // State to track number of cards to show

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      // If currently showing all, reduce the number of rows displayed
      setNumToShow(4);
    } else {
      // If currently not showing all, show all rows
      setNumToShow(products.length);
    }
  };
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <section className="our-product">
      <Container>
        <div className=" mb-md-4 mb-2 mt-md-5 mt-3">
          <h2 className="title">All Products</h2>
        </div>

        {/* desktop_view_product_category_slider start */}
        {/* <div className="desktop_view_product_category_slider">
          <Row>
          
            {products?.map((product, index) => (
              <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>
                <Product_Card
                  image={IMG_URL + product?.image1}
                  product_id={product?.id}
                  text={product?.name}
                  subText={product?.short_description}
                  price={product?.s_price}
                />
              </Col>
            ))}
          </Row>
        </div> */}

        {/* desktop_view_product_category_slider end */}

        {/* mobile_view_product_category_slider start */}

        <div className="mobile_view_product_category_slider">
          <Row className="ms-0 me-0">
            <Swiper
              className="our-product-slider mySwiper"
              modules={[Pagination,Navigation]}
              pagination={{
                dynamicBullets: true,
                clickable:true
              }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                420: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              {products?.length > 0 ? (
                products?.map((product, index) => (
                  <SwiperSlide>
                    <Product_Card
                      image={IMG_URL + product?.image1}
                      product_id={product?.id}
                      text={product?.name}
                      subText={product?.short_description}
                      price={product?.variants[0]?.s_price}
                      variant_id={product?.variants[0]?.id}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <div className="noprodiv">
                  <p>No Product Found</p>
                </div>
              )}
            </Swiper>
          </Row>
        </div>

        {/* mobile_view_product_category_slider end */}

        {showAll ? (
          <div className="view-all mt-4" onClick={toggleShowAll}>
            <p>View Less</p>
          </div>
        ) : (
          products.length > 8 && (
            <div className="view-all mt-4" onClick={toggleShowAll}>
              <p>View All</p>
            </div>
          )
        )}

        <div
          className="view-all mt-4"
          onClick={() => navigate("/select-from-category/1")}
        >
          <p>View All Products</p>
        </div>
      </Container>
    </section>
  );
};

export default OurProduct;
