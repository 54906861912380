import React, { useState, useEffect, useContext, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, fas } from "@fortawesome/free-solid-svg-icons";

import Cookies from "js-cookie";
import { Context } from "../../utils/context";
import { Category } from "../../utils/apis/Product/Product";
import { productList } from "../../utils/apis/Product/Product";
import { getCart } from "../../utils/apis/addToCart/addToCart";
import { Webheader, AppSetup } from "../../utils/apis/common/Common";
import { Accordion, ListGroup, Offcanvas } from "react-bootstrap";
import LogoutModal from "../Common_modal/LogoutModal/LogoutModal";
import Permission_Pogout_Modal from "../Common_modal/Permission_Pogout_Modal/Permission_Pogout_Modal";

library.add(fas);
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };

  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    getSetup();
    getHeader();
    getCategory();
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (id) => {
    navigate(`/select-from-category/${id}`);
  };

  const navigateToCategory = (id) => {
    navigate(`/select-from-category/${id}`);
  };

  const [searchToggled, setIsToggled] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsToggled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleClick = () => {
    setIsToggled((prevState) => !prevState);
  };

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    addCartModal,
    getData,
    updateCartCount,
    wishlistContext,
  } = useContext(Context);

  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category();
    if (res?.success) {
      setCategory(res.data);
    }
  };

  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [product_id, setProductId] = useState([]);

  const getProducts = async () => {
    const res = await getData(`/without-login/product?term=${search}`);
    if (res?.success) {
      setProducts(res?.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, [search]);

  const [header, setHeader] = useState([]);
  const getHeader = async () => {
    const res = await Webheader();
    if (res?.success) {
      setHeader(res?.data);
    }
  };

  const { IMG_URL } = useContext(Context);

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  };

  const [modalShow, setModalShow] = useState(false);

  const handleLogout = async () => {
    await Cookies.remove("local_mandai_security", { path: "/" });
    await setSignin(false);
    setModalShow(true);
    await updateCartCount();
    await navigate("/");
  };

  const handleNavigate = async (product_id) => {
    localStorage.setItem("product_id", product_id);
    window.location.href = `/vendor-page`;
    setIsToggled(false);
  };

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return (
    <>
      <section
        className="second-header-sec"
        style={{
          backgroundColor: setups?.header_color,
        }}
      >
        {isMobile ? (
          <div className="mobile_view_header">
            <Container>
              <Navbar expand="lg">
                <div className="top_content d-flex justify-content-between">
                  <Navbar.Brand as={Link} to="/" className="order-lg-1 order-2">
                    <img
                      className="headlogo"
                      src={IMG_URL + setups?.image}
                      alt="Logo"
                    />
                  </Navbar.Brand>

                  <div className="all-icon ms-auto mt-lg-0 mt-md-3 mt-sm-3 mt-3 order-lg-3 order-3">
                    <Link className="cart-icon-holder" to={"/product-cart"}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-cart-shopping"
                        className="cart-logo r"
                        onClick={handleNavItemClick}
                      />
                      <span className="count-span-holder">{cartCount}</span>
                    </Link>

                    {signin ? (
                      <>
                        {wishlistData?.length > 0 ? (
                          <Link to={"/wishlist"}>
                            <FontAwesomeIcon
                              icon={faHeart}
                              className="cart-logo"
                              onClick={handleNavItemClick}
                            />
                          </Link>
                        ) : (
                          <Link to={"/empty-wislist"}>
                            <FontAwesomeIcon
                              icon={faHeart}
                              className="cart-logo"
                              onClick={handleNavItemClick}
                            />
                          </Link>
                        )}

                        <Link to={"/my-account"}>
                          <FontAwesomeIcon
                            icon="fa-solid fa-user"
                            className="cart-logo"
                            onClick={handleNavItemClick}
                          />
                        </Link>
                        <Link className="login" onClick={handleLogout}>
                          Logout
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to={"/empty-wislist"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="cart-logo "
                            onClick={handleNavItemClick}
                          />
                        </Link>
                        <Link to={"/login"} className=" login">
                          Login
                        </Link>
                      </>
                    )}
                  </div>
                </div>

                <div className="bottom_content">
                  <div className="menu_icon_div">
                    <FontAwesomeIcon
                      icon={faBars}
                      className="menu_icon"
                      aria-controls="offcanvasNavbar order-lg-4 order-1"
                      onClick={() => setShowOffcanvas(true)}
                    />
                  </div>

                  <div ref={searchRef} className="input-group-box mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Search here"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />

                    <ListGroup>
                      {products.map((product) => (
                        <ListGroup.Item
                          key={product?.value}
                          onClick={() => handleNavigate(product?.value)}
                        >
                          {product?.label}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              </Navbar>
            </Container>
          </div>
        ) : (
          <div className="desktop_view_header">
            <div className="container-xl">
              <Navbar expand="md">
                <Navbar.Brand as={Link} to="/" className="order-lg-1 order-2">
                  <img
                    className="headlogo"
                    src={IMG_URL + setups?.image}
                    alt="Logo"
                  />
                </Navbar.Brand>

                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className="d-none d-lg-block order-lg-2 order-2"
                >
                  {header?.length > 0 ? (
                    <Nav className="mx-auto">
                      {header?.map((item, index) =>
                        item?.id === 7 ? (
                          <NavDropdown
                            title="All Categories"
                            id="basic-nav-dropdown"
                            className="me-3"
                            key={index}
                          >
                            {category?.map((value) => (
                              <NavDropdown.Item
                                key={value?.id}
                                onClick={() => navigateToCategory(value?.id)}
                              >
                                {value.name}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        ) : (
                          <Nav.Link
                            key={index}
                            as={Link}
                            to={item.url}
                            active={activeItem === item.name}
                            onClick={() => handleNavItemClick(item.name)}
                          >
                            {item.title}
                          </Nav.Link>
                        )
                      )}
                    </Nav>
                  ) : (
                    <Nav className="mx-auto">
                      <Nav.Link
                        as={Link}
                        to="/"
                        active={activeItem === "home"}
                        onClick={handleNavItemClick}
                      >
                        Home
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/near-me"
                        active={activeItem === "near-me"}
                        onClick={handleNavItemClick}
                      >
                        Near Me
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/about-us"
                        active={activeItem === "about-us"}
                        onClick={handleNavItemClick}
                      >
                        About Us
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/contact-us"
                        active={activeItem === "contact-us"}
                        onClick={handleNavItemClick}
                      >
                        Contact Us
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/blog"
                        active={activeItem === "blog"}
                        onClick={handleNavItemClick}
                      >
                        Blog
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/become-seller"
                        active={activeItem === "become-seller"}
                        onClick={handleNavItemClick}
                      >
                        Become a Seller
                      </Nav.Link>
                      <NavDropdown
                        title="All Categories"
                        id="basic-nav-dropdown"
                        className="me-3"
                      >
                        {category?.map((value) => {
                          return (
                            <NavDropdown.Item
                              key={value?.id}
                              onClick={() => navigateToCategory(value?.id)}
                            >
                              {value.name}
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                      <Nav.Link
                        as={Link}
                        to="/faq"
                        active={activeItem === "faq"}
                        onClick={handleNavItemClick}
                      >
                        FAQs
                      </Nav.Link>
                    </Nav>
                  )}
                </Navbar.Collapse>

                <div className="all-icon ms-auto mt-lg-0 mt-md-3 mt-sm-3 mt-3 order-lg-3 order-3">
                  <FontAwesomeIcon
                    icon="fa-solid fa-magnifying-glass"
                    className="cart-logo"
                    onClick={toggleClick}
                  />

                  <Link className="cart-icon-holder" to={"/product-cart"}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-cart-shopping"
                      className="cart-logo r"
                      onClick={handleNavItemClick}
                    />
                    <span className="count-span-holder">{cartCount}</span>
                  </Link>

                  {signin ? (
                    <>
                      {wishlistData?.length > 0 ? (
                        <Link to={"/wishlist"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="cart-logo"
                            onClick={handleNavItemClick}
                          />
                        </Link>
                      ) : (
                        <Link to={"/empty-wislist"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            className="cart-logo"
                            onClick={handleNavItemClick}
                          />
                        </Link>
                      )}

                      <Link to={"/my-account"}>
                        <FontAwesomeIcon
                          icon="fa-solid fa-user"
                          className="cart-logo"
                          onClick={handleNavItemClick}
                        />
                      </Link>
                      <Link className="login" onClick={handleLogout}>
                        Logout
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to={"/empty-wislist"}>
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="cart-logo "
                          onClick={handleNavItemClick}
                        />
                      </Link>
                      <Link to={"/login"} className=" login">
                        Login
                      </Link>
                    </>
                  )}

                  {searchToggled && (
                    <div ref={searchRef} className="input-group-box mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Search here"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                      <ListGroup>
                        {products.map((product) => (
                          <ListGroup.Item
                            key={product?.value}
                            onClick={() => handleNavigate(product?.value)}
                          >
                            {product?.label}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                </div>
              </Navbar>
            </div>
          </div>
        )}

        <Offcanvas
          show={showOffcanvas}
          className="secondHeaderOffcanvas d-block d-lg-none"
          onHide={() => setShowOffcanvas(false)}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <div className="logo_holder">
              <img
                className="headlogo"
                src={IMG_URL + setups?.image}
                alt="Logo"
              />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              {header?.map((item, index) =>
                item?.id === 7 ? (
                  <>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>All Categories</Accordion.Header>
                        <Accordion.Body>
                          {category?.map((value) => (
                            <NavDropdown.Item
                              key={value?.id}
                              onClick={() => {
                                navigateToCategory(value?.id);
                                setShowOffcanvas(false);
                              }}
                            >
                              {value.name}
                            </NavDropdown.Item>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                ) : (
                  <Nav.Link
                    key={index}
                    as={Link}
                    to={item.url}
                    active={activeItem === item.name}
                    onClick={() => {
                      handleNavItemClick(item.name);
                      setShowOffcanvas(false);
                    }}
                  >
                    {item.title}
                  </Nav.Link>
                )
              )}
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </section>

      <Permission_Pogout_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Header;
