import React, { useState, useContext } from "react";

import { Context } from "../../../utils/context";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Link, useNavigate } from "react-router-dom";
function Popular_Product_Card({
  content,
  image,
  id,
  imgClassName,
  className,
  textClassName,
  alt,
}) {
  const { shimmerLoader, setShimmerLoader } = useContext(Context);

  const navigate = useNavigate()

  const handleNext = async (id) => {
    navigate(`/select-from-category/${id}`);
  }

  return (
    <>
      {shimmerLoader ? (
        <ShimmerThumbnail height={300} rounded />
      ) : (
    
          <div className={className} onClick={() => handleNext(id)}>
            <img src={image} className={imgClassName} alt={alt} />
            <div className="text-holder">
              <p className={textClassName}>{content}</p>
            </div>
            <div className="bg-overlay"></div>
          </div>
   
      )}
    </>
  );
}

export default Popular_Product_Card;
